<template>
    <div>
        Distributors Migrate<br>
        <br>
        Status: {{statusTitle}} <br>
        <br>
        <v-btn
            @click="migrate">
            MIGRATE
        </v-btn>

    </div>
</template>

<script>
export default {
    data: () => ({
        status: 0
    }),
    computed: {
        statusTitle() {
            var status = this.status,
                message
            switch (status) {
                case 0:
                    message = 'not checked yet'
                    break;
            
                case 1:
                    message = 'checking'
                    break;
            
                case 2:
                    message = 'no need'
                    break;
            
                case 3:
                    message = 'need'
                    break;
            
                case 4:
                    message = 'migrating'
                    break;

                case 4:
                    message = 'migrated'
                    break;
            
                default:
                    message = 'there is something wrong'
                    break;

            }
            return message
        }
    },
    created(){
        this.needCheck()
    },
    methods: {
        async needCheck(){
            this.status = 1
            const result = await this.$store.dispatch( 'system/distributorMigrateCheck' )
            this.status = result.status?3:2
        },
        async migrate(){
            this.status = 4
            const result = await this.$store.dispatch( 'system/distributorMigrateUp' )
            // this.status = 
        }
    }
}
</script>